import { Component, OnInit, Output, EventEmitter, Inject, ElementRef, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { NgClass, PlatformLocation, Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms'
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ShareService } from '../../../services/share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import * as $ from 'jquery';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainBreadcrumbService } from '../../main-breadcrumb/main-breadcrumb.service';
import { NotificationService } from '../../../services/notification.service';
import { QuickEditComponent } from '../../quick-edit/quick-edit.component';
import { Filter } from './filter.component';


export interface access_type {
  'GET': boolean,
  'POST': boolean,
  'PATCH': boolean,
  'DELETE': boolean
}
export interface propertylist {
  id,
  property_id,
  assignment_id,
  gid,
  property_name,
  img_url,
  property_category,
  build_status,
  address,
  own_address,
  lat,
  lon,
  no_of_floors,
  attribute_info,
  status
}

export interface subgrouplist {
  group_name: any,
  id: number,
  name: string,
  property_id: any,
  labels: any,
  description: any,
  status: boolean,
  parent_id: number,
  attributes_count: number
};

export interface grouplist {
  group_name: any,
  childrens: Array<subgrouplist>,
  id: number,
  name: string,
  property_id: any,
  labels: string,
  description: any,
  status: boolean,
  parent_id: number,
  attributes_count: number,
  sub_group_count: number
};

export interface attributelist {
  name: string,
  label: string,
  labels: any,
  description: string,
  id: number,
  status: boolean,
  property_type_id: any,
  is_hidden: boolean,
  is_mandatory: boolean,
  is_listable: boolean,
  is_editable: boolean,
  is_lang_dependent: boolean,
  group_id: number,
  mutually_exclusive: any,
  dependent: any,
  parent_id: number,
  master_id: number,
  data_type: string,
  measure_type: string,
  default_value: any,
  measure_unit: string,
  quick_edit: boolean,
  has_linked_master: boolean
}

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_user_resource_post</li>
* <li>estapi_module_type_assignment_name_count_get</li>
* <li>estapi_assignments_assignment_id_get</li>
* <li>estapi_module_type_assignment_name_properties_get</li>
* <li>estapi_module_type_entity_attributegroups_get</li>
* <li>estapi_module_type_entity_attributes_get</li>
* <li>estapi_entity_masters_master_id_values_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_get</li>
* </ol>
*
*/

@Component({
  selector: 'app-block0',
  templateUrl: './block0.component.html',
  styleUrls: ['./block0.component.css']
})


export class Block0Component implements OnInit {
  dHeight: number;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  mobileview: boolean = false;
  totalProperty: number;
  selected: any;
  assignment_id: number;
  property_list: any;
  property_name_attr_id: number;
  property_category_attr_id: number;
  property_build_attr_id: number;
  loader: boolean;
  property_list_custom: Array<propertylist> = [];
  property_limit = 20;
  property_offset = 0;
  selected_property: any;
  group_list: any;
  group_custom_list: Array<grouplist>;
  group_custom_list_floor: Array<grouplist>;
  group_custom_list_unit: Array<grouplist>;
  group_limit = 500;
  group_offset = 0;
  entity_type = "property";
  attribute_custom_list: Array<attributelist>;
  attribute_custom_list_floor: Array<attributelist>;
  attribute_custom_list_unit: Array<attributelist>;
  attribute_list: any;
  search_input: string;
  property_image_attr_id: number;
  assignment_name: string = 'Assignment';
  table_master = [];
  table_master_val = [];
  property_detail: any;
  searchField: UntypedFormControl;
  enablePatch: boolean = false;
  property_type_id: number;
  assignment_detail: any = null;
  rejectedAttributeList: any = [];
  reworkAttributes: any = [];
  property_access: access_type = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  floor_access: access_type = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  unit_access: access_type = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  show_all: access_type = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  selected_user_id: number;
  user_Detail = {};
  userdata: any;
  loading: boolean = false;
  for_parcel: boolean = false;

  public statusfilter: string = '1,4,6';
  neigh_uid: string = '';
  block_uid: string = '';
  is_filtered: boolean = false;
  sort_by: any = 'updated_date';
  neighbourhoodList: any = [];
  filteredNeighList: any = [];
  previousLocation: any = {
    lat: null,
    lon: null
  }

  constructor(private fnc: CommonfunctionService, private api: ApiserviceService, private routeAct: ActivatedRoute, private _location: Location,
    private share: ShareService, private route: Router, private elementRef: ElementRef, private plocation: PlatformLocation, private dialog: MatDialog,
    private _crumbs: MainBreadcrumbService, private notification: NotificationService) {
    this.mobileview = this.share.mobile;
    // if(!this.mobileview){
    //   this.share.header_obj.finalize = true;
    // }
    if (!this.share.main_side_nav) {
      this.route.navigateByUrl('/');
      return;
    }
    if (!this.selected_user_id && localStorage.getItem('selected_user') == 'null') {
      this.selected_user_id = this.api.user_id;
    } else {
      this.selected_user_id = +localStorage.getItem('selected_user');
    }

    this.share.block0_obj = this;
    this.routeAct.params.subscribe(params => {
      this.property_offset = 0;
      this.assignment_id = params['id'];
      this.getAllowResourcesApi(); // getting allow resources
      //this.getAssignmentsDetail(this.assignment_id);
      let assign_detail = localStorage.getItem('assignment_detail');
      if (assign_detail && JSON.parse(assign_detail).assignment_id != this.assignment_id) {
        this.neighbourhoodList = [];
        localStorage.removeItem('filter_data');
      }
      if (localStorage.getItem('filter_data') == null) {
        this.statusfilter = '1,4,6'
        this.neighbourhoodList = []
        this.property_limit = 20
        this.property_offset = 0
        this.neigh_uid = null;
        this.block_uid = null;
        this.is_filtered = false;
        this.sort_by = null;
      } else {
        let filterdata: any = JSON.parse(localStorage.getItem('filter_data'));
        this.statusfilter = filterdata.status_filter == null ? '1,4,6' : filterdata.status_filter;
        this.neighbourhoodList = filterdata.neighbourhood_list == null ? [] : filterdata.neighbourhood_list;
        // this.filteredNeighList = filterdata.filteredNeigh_list == null ? [] : filterdata.filteredNeigh_list;
        this.neigh_uid = filterdata.neigh_uid;
        this.block_uid = filterdata.block_uid;
        this.is_filtered = filterdata.is_filtered;
        this.sort_by = filterdata.sort_by;
      }

      this.entity_type = "property";
      this.plocation.onPopState(() => {
        // this.route.navigateByUrl(`property/${this.assignment_id}`);
      });
    });
  }

  getfilter() {
    return this.is_filtered;
  }
  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((data: any) => {
        if (data.status == 401 || data.status == 0) {
          this.notification.notify(data.message, "success", 5000)
        }
        else {
          this.api.allowResources = data.data;
          if (!this.for_parcel) {
            this.getAllowResource();
            this.getAssignmentsDetail(this.assignment_id, true);
            this.getGroups();
          } else {
            this.property_list_custom = [];
          }
        }
      })
  }

  public getPropertyCount() {
    let url = '', status = this.statusfilter, assigned_to = "", neigh_uid = '', block_uid = '', property_type = "";

    if (this.property_type_id) property_type = 'property_type_ids=' + this.property_type_id
    if (this.statusfilter == '1,4,6') status = '1,4,6';

    if (this.entity_type == 'property') {
      if (this.selected_user_id) {
        assigned_to = "&assigned_to=" + this.selected_user_id;
      }
      if (this.neigh_uid && this.neigh_uid != 'all') {
        neigh_uid = '&neigh_uid=' + this.neigh_uid;
      }
      if (this.block_uid && this.block_uid != 'all') {
        block_uid = '&block_uid=' + this.block_uid;
      }
      url = "deadmin/" + this.assignment_detail.assignment_name + "/count?sort_asc=false&action=property_count" + property_type + "&status=" + status + assigned_to + neigh_uid + block_uid + "&get_latest_property=Y";
    }
    this.api.getEpsData(url).subscribe({
      next: (data: any) => {
        console.log(data, 'data');

      if (data.data) {
        let countData = data.data;
        if (data.data.count) this._crumbs.count = data.data.count;
        if (this.entity_type == 'property') {
          let neighbourhood_list: any = [];
          if (countData.neighbourhood) {
            neighbourhood_list = countData.neighbourhood;
            this.neighbourhoodList = neighbourhood_list;

            // this.filteredNeighList = neighbourhood_list;
          }
        }
      }
    }, error:() => {

    }})
  }

  openOptions(v: any, mobile: boolean = false): void {
    this.api.getLocation();
    this.rejectedAttributeList = [];
    this.share.selected_obj_uid = '';
    this.selected = v.id;
    this.selected_property = v;
    if (v.attribute_info != null) {
      for (let key in v.attribute_info) {
        if (v.attribute_info[key].status == -1 || (v.attribute_info[key].status == 3 && v.status == 6)) { // -1 is rejected, 3 edited by user.
          let d = {
            "keyId": Number(key),
            "status": v.attribute_info[key].status,
            "comment": v.attribute_info[key].comment
          }
          this.rejectedAttributeList.push(d);
        }
      }
    }

    if (this.share.block1_obj) {
      this.share.block1_obj.group_custom_list = null;
    }
    if (mobile == false && (this.property_access.PATCH || this.property_access.POST)) {
      this.share.header_obj.finalize = true; // to show submit icon
      if (this.share.dynamic_obj.form) {
        this.share.block3_obj.submitForm();
      }
    }

    this.share.selected_obj_uid = this.selected_property.property_id;
    this.share.main_property_obj.property_access = this.property_access;
    //this.share.main_property_obj.selected_property = JSON.parse(JSON.stringify(this.selected_property));
    this.share.main_property_obj.selected_property = this.fnc.deepCopyJson(this.selected_property);
    this.notify.emit('proList');
    if (this.selected_user_id == 0 && this.selected_property.assignment_id != this.assignment_id) {
      this.assignment_id = this.selected_property.assignment_id;
      this.getAssignmentsDetail(this.assignment_id, false);
      this.getGroups();
    }

    this.getPropertyDetail(true);

    this.share.block1_obj = null;
    this.share.block3_obj = null;
    this.share.block2_obj = null;

  }

  isActive(v) {
    if (v == this.selected) return true;
  }
  getAssignmentsDetail(id, getAttrFlag = true) {
    this.share.loader.next(true);
    this.api.getEmsData('assignments/' + id)
      .subscribe({
        next: (data: any) => {
        this.share.loader.next(false);
        this.assignment_detail = data;
        this.getAttributes(getAttrFlag);
        if (data?.type && data?.type.toLowerCase() == 'parcel_survey') {
          this.for_parcel = true;
        } else {
          this.for_parcel = false;
        }
        localStorage.setItem('assignment_detail', JSON.stringify(data));
        this.assignment_name = (data.assignment_label[this.api.language]) ? data.assignment_label[this.api.language] : data.assignment_name;
      }, error:(err) => {
      }})
  }
  getAllowResource() {
    this.property_access = this.fnc.checkResourceAccess('properties', false);
    this.floor_access = this.fnc.checkResourceAccess('floors', false);
    this.unit_access = this.fnc.checkResourceAccess('units', false);
    this.show_all = this.fnc.checkResourceAccess('showAll', false);
    if (this.share.main_property_obj) {
      this.share.main_property_obj.property_access = this.property_access;
    }
  }

  getProperties(is_scroll = false) {
    if (!this.assignment_detail) {
      this.notification.notify("Assignment detail not found", 'warn');
      return;
    }

    if(!this.getfilter()) this.getPropertyCount();

    this.loader = true;
    this.notify.emit('proList');
    this.share.block2_obj = null;
    let search_param = "", search_by_name = "", assigned_to = "", neigh_uid = '', block_uid = '';
    if (this.search_input) {
      search_param = "&property_uid=" + this.search_input;
      if (this.property_name_attr_id) {
        search_by_name = "&attribute_value=" + this.search_input;
      }
      this.property_offset = 0;
      this.property_list_custom = [];
    }
    if (!is_scroll) {
      this.selected = null;
      this.property_list = [];
      this.property_list_custom = [];
      this.property_limit = 20;
      this.property_offset = 0;
    }
    var status;
    let sort_by = (this.sort_by == null ? 'updated_date' : this.sort_by);
    if (this.statusfilter) status = '&status=' + this.statusfilter;
    if (this.neigh_uid && this.neigh_uid != 'all') { neigh_uid = '&neigh_uid=' + this.neigh_uid }
    if (this.block_uid && this.block_uid != 'all') { block_uid = '&block_uid=' + this.block_uid }
    let url;
    if (this.selected_user_id) {
      assigned_to = "&assigned_to=" + this.selected_user_id;
    }
    url = "deadmin/" + this.assignment_detail.assignment_name + "/properties?sort_by=" + sort_by + "&sort_asc=false&limit=" + this.property_limit + "&offset=" + this.property_offset + status + "&assignment_id=" + this.assignment_id + assigned_to + search_param + search_by_name + neigh_uid + block_uid;
    url += `&get_latest_property=Y`;
    this.api.getEpsData(url)
      .subscribe({
        next:(data: any) => {
        this.loader = false;
        if (is_scroll) {
          this.property_list.push(data);
        } else {
          this.property_list = data;
        }
        let img_list, image, img_url = "../../../assets/images/building_icon.png";
        let val: propertylist;
        let address = "", property_name = 'Property Name', property_category = 'Category', buil_year = 'UC';
        if(data?.length > 0){
          data.forEach((v, k) => {
            img_url = "../../../assets/images/building_icon.png";
            address = '', property_name = 'Property Name', property_category = 'Category', buil_year = 'UC';
            if (v.own_address) {
              address = this.fnc.getAddressText(v.own_address);
            }
            if (v.data) {
              if (v.data.property_name) property_name = v.data.property_name;
              if (v.data.property_category) property_category = v.data.property_category;
              if (v.data.build_year) buil_year = this.getDate(v.data.build_year);
              if (v.data.images) {
                img_list = v.data.images;
                if (img_list.length > 0) {
                  img_url = img_list[0][0];
                  image = this.fnc.getArrayValue('2', true, img_list); //get default property image
                  if (image) {
                    img_url = image[0];
                  }
                }
              }
            }
            val = {
              id: v.property_id,
              property_id: v.property_uid,
              assignment_id: v.assignment_id,
              gid: v.building_gis_uid,
              img_url: img_url,
              property_name: property_name,
              property_category: property_category,
              build_status: buil_year,
              address: address,
              own_address: v.own_address,
              lat: v.lat,
              lon: v.lon,
              no_of_floors: v.no_of_floors,
              attribute_info: v.attribute_info,
              status: v.status
            }
            this.property_list_custom.push(val);

          });
        }


        if (this.property_list_custom.length > 0) {
          let firstProperty = this.property_list_custom[0];
          this.previousLocation = {
            lat: firstProperty.lat,
            lon: firstProperty.lon
          }
          this._crumbs.addcrumb = { title: 'Property' };
          this._crumbs.mobiletitle = 'Property';
        } else {
          this._crumbs.addcrumb = { title: 'Property' };
          this._crumbs.mobiletitle = 'Property';
          this.previousLocation = {
            lat: null,
            lon: null
          }
        }
        if (!this.property_access.GET) {
          this.property_list_custom = [];
        }
      },
        error:(err) => {
          this.previousLocation = {
            lat: null,
            lon: null
          }
          this.loader = false;
          if (!is_scroll) this.property_list_custom = [];

        }}
      );
  }
  getDate(d) {
    if (d) {
      return this.fnc.formatDate(new Date(d));
    }
  }
  getGroups() {
    console.log("calling");

    let url = "deadmin/" + this.entity_type + "/attributegroups?limit=" + this.group_limit + "&offset=" + this.group_offset + "&assignment_id=" + this.share.block0_obj.assignment_id + "&api_version=V2";
    if (this.entity_type == 'property') {
      this.group_custom_list = [];
    }
    if (this.entity_type == 'property_floor') {
      this.group_custom_list_floor = [];
    }
    if (this.entity_type == 'property_unit') {
      this.group_custom_list_unit = [];
      if (this.property_type_id) {
        url += "&property_type_ids=" + this.property_type_id;
      }
    }
    this.group_list = [];
    this.loading = true;
    this.api.getEmsData(url)
      .subscribe(
        (data: any) => {
          this.loading = false;
          if (!this.group_list.length) {
            this.group_list = data?.attribute_groups;
            data?.attribute_groups?.forEach(element => {
              if (!element.parent_group_id) {
                let list = {
                  group_name: element.group_label[this.api.language],
                  childrens: [],
                  id: element.group_id,
                  name: element.group_name,
                  property_id: element.property_type_id,
                  labels: element.group_label,
                  description: element.description,
                  status: element.status,
                  parent_id: element.parent_group_id,
                  attributes_count: element.attribute_count,
                  sub_group_count: 0
                };
                let group_ctr = 0;
                let attribute_group_cout = 0;
                this.group_list.forEach(sub => {
                  if (sub.parent_group_id == element.group_id) {
                    let sub_list = {
                      group_name: sub.group_label[this.api.language],
                      id: sub.group_id,
                      name: sub.group_name,
                      property_id: sub.property_type_id,
                      labels: sub.group_label,
                      description: sub.description,
                      status: sub.status,
                      parent_id: sub.parent_group_id,
                      attributes_count: sub.attribute_count
                    }
                    list.childrens.push(sub_list);
                    attribute_group_cout += sub.attribute_count;
                    list.sub_group_count = ++group_ctr; // counting sub group
                  }
                });
                list.attributes_count += attribute_group_cout;
                if (this.entity_type == 'property') {
                  this.group_custom_list.push(list);
                }
                if (this.entity_type == 'property_floor') {
                  this.group_custom_list_floor.push(list);
                }
                if (this.entity_type == 'property_unit') {
                  this.group_custom_list_unit.push(list);
                }
              }
            });
          }
        }
      );
  }
  selectedUserName() {
    let name = null;
    if (this.assignment_detail) {
      let users = this.assignment_detail.assigned_user_details;
      if (this.api.user_id != this.selected_user_id) {
        let user_details = this.fnc.getArrayValue('user_id', this.selected_user_id, users);
        if (user_details != null) {
          let num = user_details.no_of_properties[1] + user_details.no_of_properties[4] + user_details.no_of_properties[6];
          name = user_details.user_name + ": " + num;
        }
      }
      if (this.selected_user_id == 0) name = 'All Users';
    }
    return name;
  }

  getAttributes(get_property = true) {
    let url = "deadmin/" + this.entity_type + "/attributes?sort_by=sort_order&limit=10000&offset=0&assignment_id=" + this.assignment_id;
    let desc = '', label = '', language: any;
    this.table_master = [];
    this.attribute_list = [];
    if (this.entity_type == 'property') {
      this.attribute_custom_list = [];
    }
    if (this.entity_type == 'property_floor') {
      this.attribute_custom_list_floor = [];
    }
    if (this.entity_type == 'property_unit') {
      this.attribute_custom_list_unit = [];
      if (this.property_type_id) {
        url += "&property_type_ids=" + this.property_type_id;
      }
    }
    // (get_property) ? this.loader = true : null;
    this.loading = true;
    let res = this.api.getEmsData(url);
    // this.loading = true;
    res.subscribe({
      next: (data: any) => {
        this.loading = false;
        if (!this.attribute_list.length) {
          this.attribute_list = data;
          if (this.entity_type == "property" && get_property) {
            this.getProperties();
            this.getPropertyCount();
          }
          data?.forEach(element => {
            desc = '', label = '';
            if (element.description) {
              desc = element.description[this.api.language];
            }
            if (element.attribute_label) {
              label = element.attribute_label[this.api.language];
            }
            if (element.attribute_name == 'property_name') {
              this.property_name_attr_id = element.attribute_id;
            }
            if (element.attribute_name == 'property_category') {
              this.property_category_attr_id = element.attribute_id;
            }
            if (element.attribute_name == 'build_year') {
              this.property_build_attr_id = element.attribute_id;
            }
            if (element.attribute_name == 'images') {
              this.property_image_attr_id = element.attribute_id;
            }
            // if(element.data_type == 'table'){
            if (element.master_id) this.table_master.push(element.master_id);
            // }
            let val = {
              name: element.attribute_name,
              label: label,
              labels: element.attribute_label,
              description: desc,
              id: element.attribute_id,
              status: element.status,
              property_type_id: element.property_type_id,
              is_hidden: element.is_hidden,
              is_mandatory: element.is_mandatory,
              is_listable: element.is_listable,
              is_editable: element.is_editable,
              is_lang_dependent: element.is_lang_dependent,
              group_id: element.group_id,
              mutually_exclusive: element.mutually_exclusive_attributes,
              dependent: element.dependent_attributes,
              master_id: element.master_id,
              data_type: element.data_type,
              measure_type: element.measure_unit_type,
              default_value: element.default_value,
              parent_id: element.parent_attribute_id,
              measure_unit: element.measure_unit_code,
              quick_edit: element.quick_edit,
              has_linked_master: element.has_shared_master_values
            };
            if (this.entity_type == 'property') {
              this.attribute_custom_list.push(val);
            }
            if (this.entity_type == 'property_floor') {
              this.attribute_custom_list_floor.push(val);
            }
            if (this.entity_type == 'property_unit') {
              this.attribute_custom_list_unit.push(val);
            }
          });

          setTimeout(() => {
            if (!this.for_parcel) {
              this.getMasterValue();
            }
          }, 200);
        }
      },
      error:(err) => {
        this.loader = false;
        // this.loading = false;
      }
    }

    );
    return res;
  }

  onScroll() {
    this.property_offset += this.property_limit;
    this.getProperties(true);
  }

  // getMasterValue() {
  //   let master_list = [];
  //   this.table_master_val = [];
  //   this.table_master.forEach((v) => {
  //     if (master_list.indexOf(v) == -1) {
  //       master_list.push(v);
  //     }
  //   });
  //   if (master_list.length > 0) {
  //     master_list.forEach((v) => {
  //       if (v) {
  //         let url = this.entity_type + "/masters/" + v + "/values?sort_by=master_value_name&limit=200&offset=0&status=1";
  //         if (this.property_type_id && this.entity_type == "property_unit") {
  //           url += "&property_type_ids=" + this.property_type_id;
  //         }
  //         this.api.getEmsData(url)
  //           .subscribe((data:any) => {
  //             data.forEach(element => {
  //               let val = {
  //                 label: element.master_value_label[this.api.language],
  //                 labels : element.master_value_label,
  //                 master_id: v,
  //                 master_name: element.master_value_name,
  //                 id: element.master_value_id,
  //                 status: element.status,
  //                 property_type_id: element.property_type_id,
  //                 master_value_id: element.parent_master_value_id,
  //                 parent_master_value_ids : element.parent_master_value_ids,
  //                 name : element.master_value_label[this.api.language] // adding this for drop down
  //               };
  //               this.table_master_val.push(val);
  //             });
  //           }, err=>{

  //           });
  //       }
  //     });
  //   }
  // }

  getMasterValue() {
    let master_list = [];
    this.table_master_val = [];
    this.table_master.forEach((v) => {
      if (master_list.indexOf(v) == -1) {
        master_list.push(v);
      }
    });
    if (master_list.length > 0) {
      let url = this.entity_type + "/masters/values?sort_by=master_value_name&limit=100&offset=0&status=1&master_ids=" + master_list.toString();
      if (this.property_type_id && this.entity_type == "property_unit") {
        url += "&property_type_ids=" + this.property_type_id;
      }
      this.api.getEmsData(url)
        .subscribe({
          next:(data: any) => {
          data?.forEach(element => {
            let val = {
              label: element.master_value_label[this.api.language],
              labels: element.master_value_label,
              master_id: element.master_id,
              master_name: element.master_value_name,
              id: element.master_value_id,
              status: element.status,
              property_type_id: element.property_type_id,
              master_value_id: element.parent_master_value_id,
              parent_master_value_ids: element.parent_master_value_ids,
              name: element.master_value_label[this.api.language],  // adding this for drop down
              image_url: element.image_url
            };
            this.table_master_val.push(val);
          });
        },error:()=>{}
      });
    }
  }


  getPropertyDetail(openDialog = false) {
    if (!this.assignment_detail) return;
    this.loading = true;
    let url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.selected_property.id + "?assignment_id=" + this.assignment_id;
    this.api.getEpsData(url)
      .subscribe({next:(data: any) => {
        this.property_detail = data;
        if (this.api.language == this.api.form_language || this.api.form_language == undefined) {
          localStorage.setItem('property_detail', JSON.stringify(this.property_detail));
        }
        this.loading = false;
        if (openDialog) {
          if (this.fnc.getChildArray('quick_edit', true, this.attribute_custom_list).length) {
            if ([1, 4, 6].indexOf(this.property_detail.status) > -1) {
              if (!this.mobileview) { this.notify.emit('groups'); }
              this.selected_property.pObj = this;
              let dialogRef = this.dialog.open(QuickEditComponent, {
                width: '450px',
                maxWidth: '95vw',
                maxHeight: '95vh',
                panelClass: 'create-master-panel',
                data: this.selected_property
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            } else {
              this.notification.notify('This property is already submitted/approved', 'warn');
              this.share.header_obj.finalize = false; // submit button
            }
          } else {
            this.notify.emit('groups');
            if (this.mobileview) {
              this.route.navigate(['groups']);
            }
          }
        }
      }, error:() => {
        this.loading = false;
      }})
  }

  // //collecting attributes for rework start
  // attributeRework(rejectedAttrList) {
  //   this.reworkAttributes = [];
  //   let desc = '', label = '', comment='attribStatus', language: any;
  //     for (let k = 0; k < rejectedAttrList.length; k++) {
  //       for (let j = 0; j < this.attribute_custom_list.length; j++) {
  //        if (rejectedAttrList[k].keyId == this.attribute_custom_list[j].id) {
  //           this.attribute_custom_list[j][comment] = rejectedAttrList[k].comment;
  //           this.reworkAttributes.push(this.attribute_custom_list[j]);
  //         }
  //        }
  //     }
  // }

  //collecting attributes rework end

  getMasterValueById(master_val_id) {
    let result;
    let attr = this.fnc.getArrayValue('id', this.property_category_attr_id, this.attribute_custom_list);
    if (attr) {
      let record = this.fnc.getChildArray('master_id', attr.master_id, this.table_master_val);
      if (record.length) {
        result = this.fnc.getArrayValue('id', master_val_id, record);
      }
      if (result) {
        return result.label;
      }
    }
    return "Category";


  }
  onResize(event) {
    this.dHeight = (this.share.heightService - 50);
    let e = $(this.elementRef.nativeElement);
    e.find('#pList').css('height', this.dHeight);
    e.find('#webPList').css('height', (this.dHeight - 65));
  }


  ngOnInit() {
    window.history.pushState({}, ''); // remove history to disable back
    setTimeout(() => {
      this.dHeight = (this.share.heightService - 50);
      //let e = $(this.elementRef.nativeElement);
      //e.find('#pList').css('height', this.dHeight);
     // e.find('#webPList').css('height', (this.dHeight - 90));
    }, 10);

    if (this.share.block1_obj) {
      if (this.share.block1_obj.selected_property) {
        this.selected_property = this.share.block1_obj.selected_property;
        this.selected = this.selected_property.id;
      }
    }
    this.share.block1_obj = null;


    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(1500))
      .subscribe(term => {
        this.search_input = term;
        this.getProperties(false);
      });

  }
  ngOnDestroy() {
    if (!this.mobileview) {
      this.share.header_obj.finalize = false;
    }
  }
  openFilter() {
    this.getAssignmentsDetail(this.assignment_id, false);
    let dialogRef = this.dialog.open(Filter, {
      width: '400px',
      maxWidth:'92vw',
      panelClass: 'create-master-panel',
      data: this
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

